@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Zilla+Slab:wght@600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: 'Lato', sans-serif;
    scroll-behavior: smooth;
}
.text-custom{
    font-family: 'Zilla Slab', cursive;
}